import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
  }

  getNavClass(tabName) {
    return `nav-item ${this.props.activePage == tabName ? 'active' : ''}`;
  }

  getBgColorClass() {
    let col;
    switch (this.props.activePage) {
    case 'article':
    case 'home':
      col = 'is-black';
      break;
    case 'about':
    case 'archive':
    case 'friends':
      col = 'is-gray';
      break;
    default:
      col = 'is-gray';
    }
    return col;
  }

  getLogoUrl() {
    let logo;
    switch (this.props.activePage) {

    case 'about':
    case 'archive':
    case 'friends':
      logo = `${withPrefix('/')}img/wordmark-black.svg`;
      break;
    case 'article':
    case 'home':
    default:
      logo = `${withPrefix('/')}img/wordmark-white.svg`;
    }
    return logo;
  }

  render() {
    return (
      <nav
        className={`nav ${this.getBgColorClass()}`}
        role="navigation"
        aria-label="main-navigation"
      >
        <Link to="/" className="nav-item logo-container" title="Logo">
          <img className="logo" src={this.getLogoUrl()} />
        </Link>
        <div className="nav-blurb">
    
        </div>
        <div
          className={`nav-menu`}
        >
          <Link className={this.getNavClass('archive')} to="/archive">
            Archive
          </Link>
          <Link className={this.getNavClass('about')} to="/about">
            About
          </Link>
          <Link className={this.getNavClass('home')} to="/">
            Home
          </Link>
          <Link className={this.getNavClass('friends')} to="/friends">
            Friends
          </Link>
        </div>
      </nav>
    )
  }
}

Navbar.propTypes = {
  activePage: PropTypes.string.isRequired
}

export default Navbar
